<template>
  <div class="roleMgrForm-container">
    <form-panel
      ref="formPanel"
      :form="form"
      :submitUrl="submitUrl"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
    >
      <col2-block title="角色信息">
        <el-form-item label="角色类型" prop="roleType" :rules="[{ required: true, message: '请选择角色类型', trigger: 'change' }]">
          <v-select v-model="form.roleType" :options="roleTypeOps" :disabled="roleTypeDisabled" @change="roleTypeChange"></v-select>
        </el-form-item>
        <el-form-item label="选择角色" prop="roleIds" :rules="[{ required: true, message: '请填写选择角色', trigger: 'change' }]">
          <chosenListPanel ref="roleRef" :list.sync="form.roleIds" @select="toSelectRole"></chosenListPanel>
        </el-form-item>
      </col2-block>
      <col2-block title="角色权限">
        <el-form-item label="所属一体化" :rules="[{ required: true, message: '请填写所属一体化', trigger: 'change' }]">
          <v-select v-model="platform" :options="platformOps"></v-select>
        </el-form-item>
      </col2-block>
      <col2-block title="角色权限">
        <role-permissions v-if="operaId === 1" ref="permissionsRefAdd" :unifyId="platform" :roleDataList="rolePermissionsList" handleType="add"></role-permissions>
        <role-permissions v-else ref="permissionsRefBack" :unifyId="platform" :roleDataList="rolePermissionsList" handleType="back"></role-permissions>
      </col2-block>
    </form-panel>
    <!-- 角色multiSelect begin-->
    <multi-select2
        title="角色列表"
        :isShow.sync="roleMultiShow"
        :searchParams="roleSearchParams"
        :extraParams="extraParams"
        v-bind="roleMultiParams"
        :backFill.sync="form.roleIds"
        :maxCount="3000">
          <template #searchSlot>
            <v-input label="角色名" v-model="roleSearchParams.roleName"></v-input>
          </template>
      </multi-select2>
    <!-- 角色multiSelect end-->
  </div>
</template>
<script>
import {
  getViewMenuURL,
  getRoleTypeURL,
  batchAuthURL,
  getListRoleURL,
  batchDeAuthURL
} from './api'
import { Col2Block, MultiSelect2, ChosenListPanel } from '@/components/bussiness'
import rolePermissions from 'components/bussiness/role-permissions/role-permissions'
import { generateMapByOpts } from 'common/utils'
import _ from 'lodash' // eslint-disable-line
export default {
  components: {
    Col2Block,
    rolePermissions,
    MultiSelect2,
    ChosenListPanel
  },
  data () {
    let _this = this
    return {
      operaId: this.$route.query.opera,
      submitUrl: this.$route.query.opera === 1 ? batchAuthURL : batchDeAuthURL,
      platformOps: [],
      roleTypeOps: [],
      roleTypeMap: {},
      submitConfig: {
        // submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      platform: undefined,
      form: {
        menuIds: [],
        roleIds: [],
        roleType: undefined
      },
      rolePermissionsList: [],
      // 角色 multi-select begin
      roleMultiShow: false,
      roleSearchParams: {
        roleName: ''
      },
      roleMultiParams: {
        searchUrl: getListRoleURL,
        headers: [
          {
            prop: 'roleName',
            label: '角色名'
          },
          {
            prop: 'roleTypeText',
            label: '角色类型',
            formatter (row) {
              return _this.roleTypeMap[row.roleType]
            }
          },
          {
            prop: 'orgName',
            label: '角色归属'
          },
          {
            prop: 'createTime',
            label: '创建时间'
          },
          {
            prop: 'userCnt',
            label: '关联用户'
          }
        ],
        responseParams: {
          id: 'id',
          name: 'roleName'
        },
        responseKey: {
          id: 'id',
          name: 'text'
        }
      },
      // 角色 multi-select end
      roleTypeDisabled: false
    }
  },

  computed: {
    extraParams () {
      return { roleType: this.form.roleType }
    }
  },
  mounted () {
    if (this.operaId === 1) {
      this.$setBreadcrumb('批量授权')
    } else {
      this.$setBreadcrumb('批量收权')
    }
    // this.getRoleData()
    this.getRoleType()
  },
  methods: {
    getRoleType () {
      let _this = this
      this.$axios.get(getRoleTypeURL).then(res => {
        if (res.status === '100') {
          const { data } = res
          _this.roleTypeOps = data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
          _this.roleTypeMap = generateMapByOpts(_this.roleTypeOps)
          if (!_this.form.roleType) {
            _this.form.roleType = data[0].id
            _this.getRoleData()
          }
        }
      })
    },
    async getRoleData () {
      this.roleTypeDisabled = true
      let params = {
        allowUserType: this.form.roleType
      }
      const { data, status } = await this.$axios.get(getViewMenuURL, { params })
      if (status === 100) {
        this.rolePermissionsList = data || []
        let platformOps = []
        if (data) {
          platformOps = data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
        }
        this.platformOps = platformOps
        this.platform = this.platformOps.length > 0 ? this.platformOps[0].value : undefined
      }
      this.roleTypeDisabled = false
    },
    submitBefore (data) {
      // 操作日志添加操作对象 规则：角色名称-归属层级
      let dataObject = ''
      if (this.$refs.roleRef) {
        this.$refs.roleRef.list.forEach(item => {
          dataObject += `${item.obj.roleName}-${item.obj.orgName};`
        })
      }
      if (dataObject) {
        dataObject = dataObject.substring(0, dataObject.length - 1)
      }
      data.dataObject = dataObject

      data.roleIds = data.roleIds.map(item => item.id)
      let name = this.operaId === 1 ? 'permissionsRefAdd' : 'permissionsRefBack'
      data.menuIds = this.$refs[name].getResultData()

      return true
    },
    toSelectRole () {
      if (!this.form.roleType) {
        this.$message('请先选择角色类型')
        return
      }
      this.roleMultiShow = true
    },
    roleTypeChange () {
      this.getRoleData()
      if (this.form.roleIds && this.form.roleIds.length) {
        this.form.roleIds = []
      }
    }
  }
}
</script>
