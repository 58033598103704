var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "roleMgrForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "角色信息" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色类型",
                    prop: "roleType",
                    rules: [
                      {
                        required: true,
                        message: "请选择角色类型",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.roleTypeOps,
                      disabled: _vm.roleTypeDisabled,
                    },
                    on: { change: _vm.roleTypeChange },
                    model: {
                      value: _vm.form.roleType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleType", $$v)
                      },
                      expression: "form.roleType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择角色",
                    prop: "roleIds",
                    rules: [
                      {
                        required: true,
                        message: "请填写选择角色",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("chosenListPanel", {
                    ref: "roleRef",
                    attrs: { list: _vm.form.roleIds },
                    on: {
                      "update:list": function ($event) {
                        return _vm.$set(_vm.form, "roleIds", $event)
                      },
                      select: _vm.toSelectRole,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "角色权限" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属一体化",
                    rules: [
                      {
                        required: true,
                        message: "请填写所属一体化",
                        trigger: "change",
                      },
                    ],
                  },
                },
                [
                  _c("v-select", {
                    attrs: { options: _vm.platformOps },
                    model: {
                      value: _vm.platform,
                      callback: function ($$v) {
                        _vm.platform = $$v
                      },
                      expression: "platform",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "角色权限" } },
            [
              _vm.operaId === 1
                ? _c("role-permissions", {
                    ref: "permissionsRefAdd",
                    attrs: {
                      unifyId: _vm.platform,
                      roleDataList: _vm.rolePermissionsList,
                      handleType: "add",
                    },
                  })
                : _c("role-permissions", {
                    ref: "permissionsRefBack",
                    attrs: {
                      unifyId: _vm.platform,
                      roleDataList: _vm.rolePermissionsList,
                      handleType: "back",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "multi-select2",
        _vm._b(
          {
            attrs: {
              title: "角色列表",
              isShow: _vm.roleMultiShow,
              searchParams: _vm.roleSearchParams,
              extraParams: _vm.extraParams,
              backFill: _vm.form.roleIds,
              maxCount: 3000,
            },
            on: {
              "update:isShow": function ($event) {
                _vm.roleMultiShow = $event
              },
              "update:is-show": function ($event) {
                _vm.roleMultiShow = $event
              },
              "update:backFill": function ($event) {
                return _vm.$set(_vm.form, "roleIds", $event)
              },
              "update:back-fill": function ($event) {
                return _vm.$set(_vm.form, "roleIds", $event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "searchSlot",
                fn: function () {
                  return [
                    _c("v-input", {
                      attrs: { label: "角色名" },
                      model: {
                        value: _vm.roleSearchParams.roleName,
                        callback: function ($$v) {
                          _vm.$set(_vm.roleSearchParams, "roleName", $$v)
                        },
                        expression: "roleSearchParams.roleName",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "multi-select2",
          _vm.roleMultiParams,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }